import React from 'react';
import { Container } from '../../../../components';
import { Classes } from '../../../../utils';
import styles from './Services.module.scss';


import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import { observer } from "mobx-react";
import {withTranslation} from "react-i18next/hooks";


const Services = observer(({ t }) => {

    const loadData = async () => {
        const { data } = await publicAPI.get('/services');
        return data;
    }
    const [services, setServices] = React.useState([]);

    React.useEffect(() => {
        loadData().then(res => setServices(res))
    }, [store.language])

    return (
        <Container className={ styles.component }>
            <div className="flex j-center">
                <h2>{ t('OUR_SERVICES') }</h2>
            </div>
            <div className={ Classes.join([styles.items, 'flex column mt-5']) }>
                { services && services.map(({ title, slug, image = {}, text, features }, index) => (
                    <Service
                        title={ title && (title[store.language] ? title[store.language] : title.ru) }
                        image={ `${ process.env.REACT_APP_IMG_URL }${ image ? image.url : '' }` }
                        alt={ title }
                        text={ text && (text[store.language] ? text[store.language] : text.ru) }
                        features={ features }
                        key={ index }
                        slug={ slug }
                    />
                )) }
            </div>
        </Container>
    );
})

const Service = ({ title, image, alt, text, features, slug }) => (
    <div className={ Classes.join([styles.item, 'flex']) } id={ slug }>
        <div className={ styles.image }>
            <img src={ image } alt={ alt }/>
        </div>
        <div className={ styles.content }>
            <div className={ styles.title }>
                <h3>{ title }</h3>
            </div>
            <div className={ styles.text }>
                <p>{ text }</p>
            </div>
            <ul className={ Classes.join([styles.list, 'mt-3']) }>
                { features.map((feature = {}, index) => (
                    <Feature alt={ feature.item }
                             icon={ `${ process.env.REACT_APP_IMG_URL }${ feature ? feature.item_icon.url : '' }` }
                             text={ feature.text && (feature.text[store.language] ? feature.text[store.language] : feature.text.ru) }
                             key={ feature.id ? feature.id : index }/>
                )) }
            </ul>
        </div>
    </div>
);

const Feature = ({ icon, alt, text }) => (
    <li className="flex a-center">
        <div className="flex a-center j-center">
            <img src={ icon } alt={ alt }/>
        </div>
        <span>{ text }</span>
    </li>
);

export default withTranslation()(Services);
