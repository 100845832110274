import React, {useRef, useState} from 'react';
import {Container} from '../../../../components';
import {useRippleEffect} from '../../../../hooks';
import {Classes} from '../../../../utils';
import styles from './FAQ.module.scss';

import minus from './assets/svg/minus-black.svg';
import plus from './assets/svg/plus-black.svg';
import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import {observer} from "mobx-react";

// const questions = [
//   {
//     question: 'Как получить заем?',
//     details:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam corporis nostrum sunt beatae error vitae fugiat dolore ipsam nihil libero, adipisci ratione suscipit consequuntur rem, expedita harum consectetur eos facere.',
//   },
//   {
//     question: 'Можно ли погасить заем досрочно?',
//     details:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam corporis nostrum sunt beatae error vitae fugiat dolore ipsam nihil libero, adipisci ratione suscipit consequuntur rem, expedita harum consectetur eos facere.',
//   },
//   {
//     question: 'Может ли другой человек погасить заем и забрать его ?',
//     details:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam corporis nostrum sunt beatae error vitae fugiat dolore ipsam nihil libero, adipisci ratione suscipit consequuntur rem, expedita harum consectetur eos facere.',
//   },
//   {
//     question: 'Что делать если не хватает денег на полное погашение займа',
//     details:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam corporis nostrum sunt beatae error vitae fugiat dolore ipsam nihil libero, adipisci ratione suscipit consequuntur rem, expedita harum consectetur eos facere.',
//   },
//   {
//     question: ' Егер осы айда депозитті төлеу мүмкін болмаса, не істеу керек?',
//     details:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam corporis nostrum sunt beatae error vitae fugiat dolore ipsam nihil libero, adipisci ratione suscipit consequuntur rem, expedita harum consectetur eos facere.',
//   },
// ];

const FAQ = observer(() => {
        const loadData = async () => {
            const {data} = await publicAPI.get('/faq');
            return data;
        }
        const [data, setData] = React.useState([]);

        React.useEffect(() => {
            loadData().then(res => {
                setData(res);
            });

        }, [store.language])


        return (
            <Container className={styles.component}>
                <div className="flex j-center">
                    <h2>{data.title && (data.title[store.language] ? data.title[store.language] : data.title.ru)}</h2>
                </div>
                <div className={Classes.join([styles.items, 'flex column mt-8'])}>
                    {data.list && data.list.map(({title, text}, index) => (
                        <Question
                            question={title && (title[store.language] ? title[store.language] : title.ru)}
                            details={text && (text[store.language] ? text[store.language] : text.ru)} key={index}/>
                    ))}
                </div>
            </Container>
        )
    }
);

const Question = ({question, details}) => {
    const [opened, setOpened] = useState(false);
    const button = useRef(null);
    useRippleEffect(button, styles.ripple);

    return (
        <div className={styles.item}>
            <div className="flex a-center j-between">
                <div className={styles.question}>{question}</div>
                <div className={styles.button} ref={button} onClick={() => setOpened(!opened)}>
                    <img src={opened ? minus : plus} alt="icon-black"/>
                </div>
            </div>
            {opened ? <div className={styles.details}>{details}</div> : null}
        </div>
    );
};

export default FAQ;
