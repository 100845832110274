import React from 'react';
import styles from './Menu.module.scss';
// import {Link} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";
import {observer} from "mobx-react";
import store from "../../services/store";

const Menu = observer(({list}) => {
    return (
        <div className={styles.component}>
            <ul className={styles.list}>
                {list &&
                list.map(({id, text, path = ''}) => (
                    <li className={styles.item} key={id}>
                        <Link smooth to={path} className={styles.link} title={text && (text[store.language] ? text[store.language] : text.ru)}>
                            {text && (text[store.language] ? text[store.language] : text.ru ? text.ru : text)}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default Menu;
