import React, {Component} from 'react';
import {Button, Container, Form, Input} from "../../../components";
import {Classes} from "../../../utils";
import {observer} from 'mobx-react';
import {makeObservable, observable, toJS, action, computed, configure} from 'mobx';
import {Link, Redirect} from "react-router-dom"
import {withTranslation} from "react-i18next/hooks";

import styles from './Login.module.scss';
import API from "../../../services/api";
import store from "../../../services/store";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";


class LoginStore {
    form = {
        phone: '',
        password: '',
    }
    redirect = false;

    constructor() {
        makeObservable(this, {
            redirect: observable,
            form: observable,
            onChange: action,
            onChangePassword: action,
            onPress: action,
            formInvalid: computed
        })
    }

    onChange = (field, value) => {
        this.form[field] = value;
    };
    onChangePassword = (field, value) => {
        this.form[field] = value;
    };
    onPress = async () => {
        try {
            store.toggleLoading(true);
            await API.post('users/auth/login', toJS(this.form));
            this.redirect = true;
        } catch (error) {
        } finally {
            store.toggleLoading(false);

        }
    };

    get formInvalid() {
        return this.form.phone.length !== 16 || this.form.password.length === 0;
    }
}

@observer
class Login extends Component {

    loginStore = new LoginStore();

    render() {
        const {t} = this.props;
        if (this.loginStore.redirect || store.refresh_token) {
            return <Redirect to='/cabinet'/>;
        }
        return (
            <Container className={styles.component}>
                <div className="flex j-center a-center">
                    <div className={Classes.join([styles.form])}>
                        <Form theme="auth">
                            <PhoneInput onChange={this.loginStore.onChange} type="text" name="phone"
                                        placeholder={t('PHONE')}/>
                            <Input onInput={this.loginStore.onChangePassword} type="password"
                                   name="password" placeholder={t('PASSWORD')}/>
                            <div className="flex j-around a-center">
                                <Button disabled={this.loginStore.formInvalid}
                                        onClick={this.loginStore.onPress}
                                        theme="secondary" text={t('ENTER')}
                                        type="submit" size="middle"/>
                                <Link to='/cabinet/forgetPassword'>{t('FORGET_PASSWORD')}</Link>
                            </div>
                        </Form>
                    </div>
                </div>

            </Container>
        )
    }
}

export default withTranslation()(Login);
