// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yBmIJaW4Pzv9ZE6V5ETHfg\\=\\={background:#fff;padding:150px 0px}.yBmIJaW4Pzv9ZE6V5ETHfg\\=\\= .vH989lW0tGTSdjgYHsQtGQ\\=\\={width:100%;max-width:570px}.yBmIJaW4Pzv9ZE6V5ETHfg\\=\\= .vH989lW0tGTSdjgYHsQtGQ\\=\\= input{margin-bottom:20px}.yBmIJaW4Pzv9ZE6V5ETHfg\\=\\= .vH989lW0tGTSdjgYHsQtGQ\\=\\= button{color:#000 !important}.yBmIJaW4Pzv9ZE6V5ETHfg\\=\\= .vH989lW0tGTSdjgYHsQtGQ\\=\\= div{display:flex;justify-content:center}.yBmIJaW4Pzv9ZE6V5ETHfg\\=\\= .vH989lW0tGTSdjgYHsQtGQ\\=\\= a{color:#8c97ac}", "",{"version":3,"sources":["webpack://./src/views/cabinet/Login/Login.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,iBAAA,CAEA,wDACE,UAAA,CACE,eAAA,CACF,8DACE,kBAAA,CAEF,+DACE,qBAAA,CAEF,4DACE,YAAA,CACA,sBAAA,CAEF,0DAEE,aAAA","sourcesContent":[".component{\n  background: #fff;\n  padding: 150px 0px;\n\n  & .form{\n    width: 100%;\n      max-width: 570px;\n    & input{\n      margin-bottom: 20px;\n    }\n    & button{\n      color:#000 !important;\n    }\n    & div{\n      display: flex;\n      justify-content: center;\n    }\n    & a{\n      //text-decoration: none;\n      color:#8C97AC;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "yBmIJaW4Pzv9ZE6V5ETHfg==",
	"form": "vH989lW0tGTSdjgYHsQtGQ=="
};
export default ___CSS_LOADER_EXPORT___;
