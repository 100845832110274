import React from 'react';
import CreditCalculator from "../utils/components/CreditСalculator/CreditСalculator";
import {ServicesConditions} from "../utils/components";

const Conditions = () => {
    return (
        <>
            <CreditCalculator/>
            <ServicesConditions/>
        </>
    )
}

export default Conditions;