export default {
  RU: 'Орыс',
  KK: 'Қазақ',
  EN: 'Ағылшын',
  Register: 'Тіркеу',
  News: 'Жаңалықтар',
  NewsDetails: 'Жаңалықтар',
  Payments: 'Төлемдер',
  Login: 'Кіру',
  LOGOUT: 'Шығу',
  ENTER: 'Кіру',
  CLOSE: 'Жабу',
  SEND: 'Жіберу',
  GET_REGISTER: 'Тіркелу',
  FORGET_PASSWORD: 'Құпия сөзді ұмыттыңыз ба?',
  Calculators: 'Калькуляторлар',
  CALCULATORS_ACTUAL_PRICING: 'Ағымдағы бағалар',
  CALCULATORS_PROBA: 'Сынама',
  CALCULATORS_WEIGHT: 'Салмағы (гр)',
  CALCULATORS_DAYS: 'Мерзімі (1-30 күн)',
  CALCULATORS_GOLD: 'Алтын',
  CALCULATORS_CALCULATE: 'Есептеу',
  CALCULATORS_GIVEN_AMOUNT: 'Қолдағы сома',
  CALCULATORS_RETURN_AMOUNT: 'Қайтару сомасы',
  FILIALS_LIST: 'Тізім',
  FILIALS_MAP: 'Картада',
  Filials: 'Ақпарат',
  KM: 'км.',
  PERCENT: 'Пайыз',
  FEEDBACKS: 'Кері байланыс',
  FeedbackAdd: 'Хабар жіберу',
  FEEDBACKS_OPENED: 'Жауап күтілуде',
  FEEDBACKS_CLOSED: 'Жауап бар',
  FeedbackDetails: 'Өтініш',
  FEEDBACK_TITLE: 'Өтініш тақырыбы',
  FEEDBACK_QUESTION: 'Сипаттамасы',
  FEEDBACK_POST_SUCCESS: 'Өтініш сәтті жіберілді',
  FEEDBACK_OPENED_DATE: 'Өтінішті беру күні',
  FEEDBACK_ANSWER: 'Жауабы',
  GOLD_CALCULATOR: 'Алтын',
  TECH_CALCULATOR: 'Техника',
  TECH_CALCULATOR_INPUT_PLACEHOLDER: 'Құрылғы бағасы',
  TECH_CALCULATOR_TECH_PERCENT_OLD: 'Т/Б',
  TECH_CALCULATOR_TECH_PERCENT_NEW: 'Жаңа',
  TECH_CALCULATOR_CALC_AMOUNT: 'Шамамен құны',
  BIOMETRIC: 'Қолданбаға кіру үшін {{biometryType}} пайдалану',
  PHONE: 'Телефоны',
  PASSWORD: 'Пароль',
  EMAIL_VALIDATION_ERROR: 'Еmail пішімі қате',
  EMAIL_LABEL:
    'E-mail енгізіңіз (Бұдан әрі бұл мекенжай парольді қалпына келтіру үшін пайдаланылатын болады)',
  NEW_PASSWORD: 'Жаңа пароль',
  PASSWORD_NOTE:
    'Парольде бас және кіші латын әріптері мен сандары болуы тиіс. Пароль ұзындығы-кем дегенде 6 таңба.',
  PASSWORDS_NOT_EQUAL: 'Парольдер сәйкес келмейді',
  REPEAT_PASSWORD: 'Парольді растаңыз',
  EDIT_PROFILE_NOTE: 'Жеке кабинетке кіру үшін өз пароліңізді орнатыңыз',
  EDIT_PROFILE_SUBNOTE:
    'ППарольді кез келген уақытта, Жеке кабинетте өзгертуге болады',
  ENTER_SMS_CODE_NOTE: 'SMS-тегі кодты енгізіңіз',
  ENTER_CODE: 'Қосымшаға жылдам кіру үшін кодты енгізіңіз',
  ENTER_NEW_CODE:
    'Қосымшаға жылдам қол жеткізу үшін 4 таңбалы кодты ойлап табыңыз',
  ENTER_BASE_WITH_AUTH: 'Қалыпты кірісті пайдалану',
  SET_PASSWORD: 'Парольді орнату',
  YES: 'Иә',
  NO: 'Жоқ',
  Pawns: 'Менің кепілдерім',
  NEXT_PAYMENT: 'Келесі төлем',
  PAWN: 'Кепіл заты',
  PAWN_AMOUNT: 'Несие сомасы',
  PAWN_DETAIL: 'Толығырақ',
  PAWN_TARIFF: 'Тариф',
  PAWN_ID: 'Кепіл нөмірі',
  PAWN_CREATION_DATE: 'Кепіл күні',
  PAWN_END_DATE: 'Аяқталу күні',
  PAWN_ITEMS: 'Кепіл',
  PAWN_NEXTPAYMENT_DATE: 'Дейін төлеу',
  PAWN_NEXTPAYMENT_AMOUNT: 'Берешек сомасы',
  PAWN_LASTPAYMENT_AMOUNT: 'Кредиттің соңғы күніне берешек сомасы',
  PAWN_FILIAL_NAME: 'Филиал',
  PAY: 'Төлемді енгізу',
  PAY_CONFIRM: 'Растау',
  PAYMENT_AMOUNT: 'Төлем сомасы',
  NotificationDetails: 'Хабарлама',
  Notifications: 'Хабарламалар',
  Profile: 'Жеке кабинет',
  PAYMENT: 'Төлем',
  PAYMENT_FAILED: 'Бір нәрсе дұрыс емес',
  PAYMENT_SUCCESS: 'Төлем сәтті өтті',
  BACK_TO_PAWN: 'Кепілге оралу',
  ResetPassword: 'Парольді қалпына келтіру',
  EMAIL_RESET:
    'Көрсетілген e-mail-ге парольді қалпына келтіру үшін код жіберіледі',
  CHANGE_PASSWORD: 'Парольді өзгерту',
  CHANGE_LANGUAGE: 'Тілді өзгерту',
  CHOOSE_LANGUAGE: 'Тілді таңдау',
  PAYMENT_ORDERID: 'Төлемнің нөмірі',
  CAN_PAY_DATE: '23:30-дан 08:00-ге дейін төлем жасалмайды',
  ANY_BANKS: '23:30-дан 08:00-ге дейін төлем жасалмайды',
  CHOOSE_FROM_LIST: 'Тізімнен таңдаңыз',
  NO_DATA_SORRY: 'Тізім бос',
  SERVICE_NOT_AVAILABLE: 'Қызмет уақытша қол жетімді емес',
  SEARCH: 'Іздеу ...',
  FIELD_IS_REQUIRED: '* міндетті өріс',
  IIN: 'ЖСН',
  COUNT_LETTERS: '{{value}} таңба',
  MIN_LETTERS: 'Ең аз {{value}} таңба',
  ENTER_IIN: 'ЖСН енгізіңіз',
  WRONG_CODE: 'Код дұрыс емес',
  EnterCode: 'SMS-тен код',
  RESET_BY_EMAIL: 'Электрондық пошта арқылы',
  RESET_BY_PHONE: 'Телефон арқылы',
  FindPawns: 'Салымды төлеу',
  PAWN_NOT_FOUND: 'Кепіл табылмады',
  GENERAL:'Басты',
  FindPawnsBtn:'Ұзарту',
  SERVICES:'Қызметтер',
  OUR_SERVICES:'Біздің қызметтер',
  NAME_SURNAME:'Аты-жөні',
  SUBMIT_REQUEST:'Өтінім беру',
  Employment_questions:'Жұмысқа орналастыру мәселелері',
  INPUT_CITY:'Қала атауын енгізіңіз',
  FILIALS_PAGE:'Филиалдар',
};
