import React, {Component} from 'react';
import {Button, Container, Input} from "../../../../../components";
import styles from './Payments.module.scss';
import {PaymentCard} from "../index";
import {observer} from "mobx-react";
import {observable, action} from 'mobx';
import store from "../../../../../services/store";
import PawnsStore from "../../../../../stores/PawnsStore";
import FlatList from 'flatlist-react';
import {Classes} from "../../../../../utils";
import Spinner from "../../../../../components/Spinner";


@observer
class Payments extends Component {
    list = React.createRef();

    // @action
    // _scrollTop = () => {
    //     this.scrollButton = false;
    //     this.list.current.scrollToIndex({index: 0, animated: true});
    // };
    // @action
    // _onRefresh = () => {
    //     if (!this.props.listStore.refreshing) {
    //         this.props.listStore.setRefreshing(true);
    //         this.props.listStore.getItems(1);
    //     }
    // };
    //
    // @action
    // _onEndReached = () => {
    //     if (
    //         !this.props.listStore.loading &&
    //         this.props.listStore.query.page < this.props.listStore.totalPages
    //     ) {
    //         this.props.listStore.setLoading(true);
    //         this.props.listStore.getItems(this.props.listStore.query.page + 1);
    //     }
    // };

    // @action
    // onMomentumScrollBegin = event => {
    //     this.scrollButton = event.nativeEvent.contentOffset.y > 70;
    // };

    componentDidMount() {
        store.toggleLoading(true);
        this.getItems();
    }

    getItems = async () => {

        try {
            // const data = await this.props.listStore.getItems();
            const data = await PawnsStore.getItems();
            // this.props.onGetItems && this.props.onGetItems(data);
        } catch (error) {
        }
    };

    renderItem = (item, idx) => {
        return (
                <PaymentCard key={item._id} item={item}/>
        )
    }

    render() {
        return (
            <div className={Classes.join([styles.component, "flex column a-center j-start"])}>
                <h2>Платежи</h2>
                <FlatList list={PawnsStore.items}
                          renderItem={this.renderItem}
                          renderWhenEmpty={()=>'Список текущих платежей пуст'}
                          renderOnScroll
                />
            </div>


        )
    }
}

export default Payments;
