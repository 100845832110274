// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1u3ddvYLbneBI3brzGieVg\\=\\={width:100%}._1u3ddvYLbneBI3brzGieVg\\=\\= h3{font-weight:bold;text-align:center}._1u3ddvYLbneBI3brzGieVg\\=\\= h2{margin-bottom:100px}._1u3ddvYLbneBI3brzGieVg\\=\\= input{box-shadow:0px 4px 26px rgba(0,0,0,.06);margin:15px 0px}._1u3ddvYLbneBI3brzGieVg\\=\\= form{width:90%}._1u3ddvYLbneBI3brzGieVg\\=\\= form button{color:#000 !important;margin:15px auto}", "",{"version":3,"sources":["webpack://./src/views/cabinet/utils/components/ResetPassword/ResetPassword.module.scss"],"names":[],"mappings":"AAAA,6BACE,UAAA,CACA,gCACE,gBAAA,CACA,iBAAA,CAEF,gCACE,mBAAA,CAEF,mCACE,uCAAA,CACA,eAAA,CAEF,kCACE,SAAA,CACA,yCACE,qBAAA,CACA,gBAAA","sourcesContent":[".component{\n  width: 100%;\n  h3{\n    font-weight: bold;\n    text-align: center;\n  }\n  & h2{\n    margin-bottom: 100px;\n  }\n  input{\n    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);\n    margin: 15px 0px;\n  }\n  form{\n    width: 90%;\n    button{\n      color:#000 !important;\n      margin: 15px auto;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_1u3ddvYLbneBI3brzGieVg=="
};
export default ___CSS_LOADER_EXPORT___;
