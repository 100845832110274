import React, { Component } from 'react';
import styles from './CreditСalculator.module.scss';
import { observer } from "mobx-react";
import { observable, action, computed, makeObservable } from 'mobx';
import store from "../../../../services/store";
import { Classes } from "../../../../utils";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import API from "../../../../services/api";
import { Form, Input, RadioButton } from "../../../../components";
import InputCustom from "../../../../components/InputCustom/Input";
import { withTranslation } from "react-i18next/hooks";

class CalcStore {
    constructor() {
        makeObservable(this, {
            _tariff : observable,
            tariff : computed,
            getTariffs : action,
        })
    }

    _tariff = null;
    get tariff() {
        return this._tariff;
    }

    set tariff(v) {
        this._tariff = v;
    }

    getTariffs = async () => {
        try {
            const { data } = await API.get('public/tariffs');
            this.tariff = data.docs || [];
            const [tariff] = data.docs || [];
            this.tariff = tariff;
        } catch (error) {
            console.log(error);
        } finally {
        }
    };


}

const calcStore = new CalcStore();

@observer
class CreditCalculator extends Component {

    componentDidMount() {
        calcStore.getTariffs();
    }

    // componentWillUnmount() {
    //     this.focusListener && this.focusListener.remove();
    // }

    render() {
        const { t } = this.props;
        return (
            <div className={ Classes.join([styles.component, 'container flex j-center mt-5 mb-5']) }>
                <Tabs className={ styles.calcTabs }
                      selectedTabClassName={ styles.active }
                      selectedTabPanelClassName={ styles.selectedTab }>
                    <TabList>
                        <Tab>{ t('GOLD_CALCULATOR') }</Tab>
                        <Tab>{ t('TECH_CALCULATOR') }</Tab>
                    </TabList>

                    <TabPanel>
                        <GoldCalculator { ...this.props } tariff={ calcStore.tariff }/>
                    </TabPanel>
                    <TabPanel>
                        <TechCalculator { ...this.props } tariff={ calcStore.tariff }/>

                    </TabPanel>
                </Tabs>

            </div>

        )
    }
}

class TechStoreG {
    _price = '0';

    constructor(props) {
        this.props = props;
        makeObservable(this, {
            _price : observable,
            price : computed,
            onChange : action,
            money : computed,
            percent : computed,
            givenAmount : computed,
            _type : observable,
            type : computed,
            changeType : action,
        })
    }

    get price() {
        return this._price;
    }

    set price(v) {
        v = v.startsWith('0') ? v.slice(1) : v;
        this._price = v;
    }

    onChange = (name, value) => {
        console.log(this.props)
        this.price = value.replace(/ /g, '');
    };

    get money() {
        return this.price.includes('.')
            ? this.price
            : this.price.replace(/ /g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    _type = 'tech_percent_old';

    changeType(type) {

        this._type = type;
    }

    get type() {
        return this._type;
    }

    get percent() {
        return this.props.tariff ? this.props.tariff[this.type] || 0 : 0;
    }

    get givenAmount() {
        return (this.price * (this.percent / 100)).toFixed(0);
    }


}


@observer
class TechCalculator extends Component {
    componentDidMount() {
        this.getTariff();
    }
    getTariff = async () =>{
        const { data:res } = await API.get('public/tariffs');
        const [tariff] = res.docs || [];
        this.tariff = tariff;
    }
    techStore = new TechStoreG(this.props);

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Form>
                    <Input type='number'
                           name='price'
                           placeholder={ t('TECH_CALCULATOR_INPUT_PLACEHOLDER') }
                           onInput={ this.techStore.onChange }
                    />
                </Form>
                <div className='flex mt-3 mb-3'>
                    <RadioButton active={ this.techStore.type === 'tech_percent_old' }
                                 label={ t('TECH_CALCULATOR_TECH_PERCENT_OLD') }
                                 onChange={ () => this.techStore.changeType('tech_percent_old') }
                                 onClick={ () => this.techStore.changeType('tech_percent_old') }
                    />
                    <RadioButton active={ this.techStore.type === 'tech_percent_new' }
                                 label={ t('TECH_CALCULATOR_TECH_PERCENT_NEW') }
                                 onChange={ () => this.techStore.changeType('tech_percent_new') }
                                 onClick={ () => this.techStore.changeType('tech_percent_new') }
                    />

                </div>
                <h6>{ t('CALCULATORS_CALCULATE') }</h6>
                <div className={ Classes.join([styles.calculateBlock, "flex j-around mt-3 pt-4 pb-4 pl-2 pr-2"]) }>
                    <div className='flex column a-center'>
                        <span>{ t('TECH_CALCULATOR_CALC_AMOUNT') }</span>
                        <p className='mt-2'>{ Number(this.techStore.givenAmount).format() } ₸ </p>
                    </div>
                    <div className='flex column a-center'>
                        <span>{ t('PERCENT') }</span>
                        <p className='mt-2'>{ Number(this.techStore.percent).format() }% </p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

class GoldStore {

    constructor(props) {
        makeObservable(this,
            {
                props:observable,
                returnAmount : computed,
                golds : computed,
                _golds : observable,
                _selectedPrice : observable,
                _weight : observable,
                weight : computed,
                _days : observable,
                days : computed,
                _refreshing : observable,
                refreshing : computed,
                tariff : computed,
                selectedPrice : computed,
                givenAmount : computed,
            })
    }

    props= {};

    _golds = [];
    get golds() {
        return this._golds.slice();
    }

    set golds(v) {
        this._golds = v;
    }

    _selectedPrice = null;
    get selectedPrice() {
        return this._selectedPrice;
    }

    set selectedPrice(v) {
        this._selectedPrice = v;
    }

    _weight = '1';

    get weight() {
        return (this._weight.includes('.')
                ? this._weight
                : this._weight.replace(/ /g, '')
        ).toString();
    }

    set weight(value) {
        value = value.replace(/-/g, '');
        value =
            this.weight.includes('.') &&
            (value.includes(',') ||
                (value.match(/\./g) && value.match(/\./g).length === 2))
                ? this.weight
                : value.replace(/,/g, '.');
        value = value[0] === '.' ? `0${ value }` : value;
        this._weight = value;
    }

    _days = '2';
    get days() {
        return this._days.toString();
    }

    set days(v) {
        v = v < 1 ? '' : v > 60 ? 60 : v;

        this._days = v;
    }

    _refreshing = false;
    get refreshing() {
        return this._refreshing;
    }

    set refreshing(v) {
        this._refreshing = v;
    }


    getGolds = async () => {
        try {
            this.refreshing = true;
            const { data } = await API.get('public/golds');
            this.golds = data.docs || [];
            this.selectedPrice = this.golds[0] ? this.golds[0].price : null;
            const { data:res } = await API.get('public/tariffs');
            const [tariff] = res.docs || [];
            this.props.tariff = tariff;
        } catch (error) {
            console.log(error);
        } finally {
            this.refreshing = false;
            store.toggleLoading(false);
        }
    };


    get givenAmount() {
        return Math.trunc(this.selectedPrice * this.weight);
    }

    get tariff() {
        return this.givenAmount > 138000
            ? 0.00155
            : this.props.tariff
                ? this.props.tariff.gold_percent
                : 0;
    }

    get returnAmount() {
        return Math.trunc(
            this.givenAmount + this.givenAmount * this.tariff * this.days,
        );
    }

}


@observer
class GoldCalculator extends Component {
    goldStore = new GoldStore(this.props);

    componentDidMount() {
        this.goldStore.getGolds();
    }

    renderItem = ({ item }) => {
        const { t } = this.props;
        return (
            <div className={ Classes.join([styles.goldItem, 'flex j-between pt-15 pb-15 pl-15 pr-15 mb-15']) }
                 key={ item._id }>
                <p className={ styles.proba }>
                    { t('CALCULATORS_PROBA') } AU { item.proba }
                </p>
                <p className={ styles.price }>{ item.price } ₸</p>
            </div>
        );
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <h5 className='mb-15'>{ t('CALCULATORS_ACTUAL_PRICING') }</h5>
                { this.goldStore.golds.map((item, index) => this.renderItem({ item, index })) }
                <h6>{ t('CALCULATORS_CALCULATE') }</h6>
                <div
                    className={ Classes.join([styles.calculateBlock, "flex column a-center j-center mt-3 pt-4 pb-4 pl-2 pr-2"]) }>
                    <div className="flex j-around">
                        <div className='flex column a-center'>
                            <span>{ t('CALCULATORS_GIVEN_AMOUNT') }</span>
                            <p className='mt-1'>{ Number(this.goldStore.givenAmount).format() } ₸ </p>
                        </div>
                        <div className='flex column a-center'>
                            <span>{ t('CALCULATORS_RETURN_AMOUNT') }</span>
                            <p className='mt-1'>{ Number(this.goldStore.returnAmount).format() } ₸ </p>
                        </div>
                    </div>
                    <hr/>
                    <div className={ Classes.join([styles.goldComputed, "flex column a-center mt-3"]) }>
                        <label className='flex column a-center pt-2 pr-2 pl-2 pb-2'>
                            { t('CALCULATORS_PROBA') }
                            <select
                                value={ this.goldStore.selectedPrice ? this.goldStore.selectedPrice : 375 }
                                onChange={ v => (this.goldStore.selectedPrice = v.target.value) }
                                className='mt-15'>
                                { this.goldStore.golds.map(x => (
                                    <option
                                        key={ x._id }
                                        value={ x.price }
                                    >
                                        { `${ t('CALCULATORS_GOLD') } ${ x.proba }` }
                                    </option>
                                )) }
                            </select>
                            <span className='mt-2'>{ t('CALCULATORS_WEIGHT') }</span>
                            <Input
                                value={ this.goldStore.weight }
                                name='weight'
                                onInput={ (n, v) => (this.goldStore.weight = v) }
                                placeholder={ t('CALCULATORS_WEIGHT') }
                            />
                            <span className='mt-2'>{ t('CALCULATORS_DAYS') }</span>
                            <InputCustom
                                name='days'
                                type='text'
                                onChange={ (v) => (this.goldStore.days = v.target.value) }
                                placeholder={ t('CALCULATORS_DAYS') }
                            />
                        </label>

                    </div>
                </div>


            </React.Fragment>
        )
    }
}

export default withTranslation()(CreditCalculator);
