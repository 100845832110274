import i18n from 'i18next';
// import {i} from 'react-i18next';

import {initReactI18next} from "react-i18next/hooks";

import ru from '../assets/i18n/ru';
import kk from '../assets/i18n/kk';
import en from '../assets/i18n/en';

const resources = {
  ru: {
    translation: ru,
  },
  kk: {
    translation: kk,
  },
  en: {
    translation: en,
  },
};
const detector = {
  type: 'languageDetector',
  async: true,

  init: () => {},
  detect: function(callback) {
    return callback('ru');
  },
  cacheUserLanguage: function(language) {},
};

i18n
  .use(detector)
  .use(initReactI18next)

  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    cache: {
      enabled: true,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
