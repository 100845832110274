// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gQ9Y515fVlQU\\+RBMwISaaw\\=\\={background:#fff;padding:150px 0px}.gQ9Y515fVlQU\\+RBMwISaaw\\=\\= ._5jFwgM64k\\+ZT5RVYPzRkAQ\\=\\={width:100%;max-width:570px}.gQ9Y515fVlQU\\+RBMwISaaw\\=\\= ._5jFwgM64k\\+ZT5RVYPzRkAQ\\=\\= input{margin-bottom:20px}.gQ9Y515fVlQU\\+RBMwISaaw\\=\\= ._5jFwgM64k\\+ZT5RVYPzRkAQ\\=\\= button{color:#000 !important}.gQ9Y515fVlQU\\+RBMwISaaw\\=\\= ._5jFwgM64k\\+ZT5RVYPzRkAQ\\=\\= div{display:flex;justify-content:center}.gQ9Y515fVlQU\\+RBMwISaaw\\=\\= ._5jFwgM64k\\+ZT5RVYPzRkAQ\\=\\= a{color:#8c97ac}", "",{"version":3,"sources":["webpack://./src/views/cabinet/ForgetPassword/ForgetPassword.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,iBAAA,CAEA,2DACE,UAAA,CACE,eAAA,CACF,iEACE,kBAAA,CAEF,kEACE,qBAAA,CAEF,+DACE,YAAA,CACA,sBAAA,CAEF,6DAEE,aAAA","sourcesContent":[".component{\n  background: #fff;\n  padding: 150px 0px;\n\n  & .form{\n    width: 100%;\n      max-width: 570px;\n    & input{\n      margin-bottom: 20px;\n    }\n    & button{\n      color:#000 !important;\n    }\n    & div{\n      display: flex;\n      justify-content: center;\n    }\n    & a{\n      //text-decoration: none;\n      color:#8C97AC;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "gQ9Y515fVlQU+RBMwISaaw==",
	"form": "_5jFwgM64k+ZT5RVYPzRkAQ=="
};
export default ___CSS_LOADER_EXPORT___;
