import React from 'react';
import {Container} from '../../../../components';
import {Classes} from '../../../../utils';
import styles from './About.module.scss';

import about from './assets/images/about-us.png';
import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import {observer} from "mobx-react";

const About = observer(() => {
    const loadAbout = async () => {
        const {data} = await publicAPI.get('/about-section');
        return data;
    }
    const [about, setAbout] = React.useState([]);

    React.useEffect(() => {
        loadAbout().then(res => setAbout(res))
    }, [store.language])
    return (

        <Container className={styles.component}>
            {about &&
            <div className="flex j-center">
                <h2>{about.title && (about.title[store.language] ? about.title[store.language] : about.title.ru)}</h2>
            </div>
            }
            {about &&
            <div className={Classes.join([styles.container, 'flex j-between mt-55'])}>
                <div className={styles.image}>
                    <img src={`${process.env.REACT_APP_IMG_URL}${about.image ? about.image.url : ''}`} alt="about-us"/>
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <h4>
                            {about.subtitle && (about.subtitle[store.language] ? about.subtitle[store.language] : about.subtitle.ru)}
                        </h4>
                    </div>
                    <div className={styles.text}>
                        <p>{about.text && (about.text[store.language] ? about.text[store.language] : about.text.ru)}</p>
                    </div>
                </div>
            </div>
            }
        </Container>

    );
})

export default About;
