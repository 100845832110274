import React from 'react';
import {Container} from '../../../../components';
import {Classes} from '../../../../utils';
import styles from './CreditConditions.module.scss';
import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import {observer} from "mobx-react";

const CreditConditions = observer(() => {

        const loadData = async () => {
            const {data} = await publicAPI.get('/credit-conditions');
            return data;
        }
        const [data, setData] = React.useState([]);

        React.useEffect(() => {
            loadData().then(res => {
                setData(res);
            });

        }, [store.language])


        return (
            <Container className={styles.component}>
                <div className="flex j-center">
                    <h2>{data.title && (data.title[store.language] ? data.title[store.language] : data.title.ru)}</h2>
                </div>
                <div className={Classes.join([styles.items, 'flex wrap j-between mt-8'])}>
                    {data.list && data.list.map(({item_icon, text}, index) => (
                        <div className={styles.item} key={index}>
                            <div className={styles.image}>
                                <img
                                    src={`${process.env.REACT_APP_IMG_URL}${item_icon ? item_icon.url : ''}`}
                                    alt={text && (text[store.language] ? text[store.language] : text.ru)}
                                />
                            </div>
                            <div className={styles.text}>
                                {text && (text[store.language] ? text[store.language] : text.ru)}
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        );
    }
);

export default CreditConditions;
