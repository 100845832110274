import React, {useCallback, useMemo} from 'react';
import {Classes} from '../../utils';
import styles from './PhoneInput.module.scss';
import InputMask from 'react-input-mask';
import {Input} from "../index";


const PhoneInput = (props) => {
    const onChange = useCallback(
        text => {
            props.onChange && props.onChange(props.name, text.target.value);
        },
        [props],
    );
    const invalid = useMemo(
        () =>
            props.invalid && props.value && props.value.length > 0
                ? styles.invalid
                : null,
        [props.invalid, props.value],
    );

    return (
        <div className={Classes.join([styles.component, invalid ? styles.invalid : null])}>
            <InputMask mask="+7 999 999 99 99" maskchar=" " name={props.name} value={props.value}
                       onChange={onChange} placeholder={props.placeholder}/>
            {invalid ? <small>{props.errorMessage ? props.errorMessage : 'Данное поле является обязательным!' }</small> : null}
        </div>
    );
};

export default PhoneInput;
