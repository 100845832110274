// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3grvy78sR6V96SE2MHahOQ\\=\\={width:100%}._3grvy78sR6V96SE2MHahOQ\\=\\=>div:nth-child(1){min-width:220px}._3grvy78sR6V96SE2MHahOQ\\=\\=>div:nth-child(2){width:60%}@media(max-width: 640px){._3grvy78sR6V96SE2MHahOQ\\=\\=>div:nth-child(1){width:100%}}", "",{"version":3,"sources":["webpack://./src/views/cabinet/Profile/Profile.module.scss"],"names":[],"mappings":"AAAA,6BACE,UAAA,CACA,8CAEE,eAAA,CAEF,8CACE,SAAA,CAEF,yBACE,8CACE,UAAA,CAAA","sourcesContent":[".component{\n  width: 100%;\n  & > div:nth-child(1){\n    //width:40%;\n    min-width: 220px;\n  }\n  & > div:nth-child(2){\n    width:60%;\n  }\n  @media(max-width:640px ){\n    & > div:nth-child(1){\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_3grvy78sR6V96SE2MHahOQ=="
};
export default ___CSS_LOADER_EXPORT___;
