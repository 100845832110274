// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fgzXXoeW3t914KIVGUPMFw\\=\\={padding:50px 0 80px;background-color:#ffea00}@media(max-width: 768px){.fgzXXoeW3t914KIVGUPMFw\\=\\= .oG7f1Ds-Jkyli0w8Xbjjzg\\=\\={flex-wrap:wrap;justify-content:space-around !important}}.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\={width:30%;min-width:220px;padding:0px;margin:30px 0;text-align:center}@media(max-width: 768px){.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\={width:47%}}@media(max-width: 480px){.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\={width:100%}}.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\= ._8KsISkZTYbnS7X2cIjmQOw\\=\\={font-size:52px;font-weight:700}.fgzXXoeW3t914KIVGUPMFw\\=\\= .qhhOOHeaHDLNF\\+jRvbLDMQ\\=\\= .AW\\+Wk\\+W1BEcMGQAV7IxSuA\\=\\={margin:15px 0 0;font-size:24px;font-weight:700}", "",{"version":3,"sources":["webpack://./src/views/Home/components/About/About.module.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CACA,wBAAA,CAGE,yBADF,wDAEI,cAAA,CACA,uCAAA,CAAA,CAIJ,yDACE,SAAA,CACA,eAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CACA,yBANF,yDAOI,SAAA,CAAA,CAEF,yBATF,yDAUI,UAAA,CAAA,CAGF,sFACE,cAAA,CACA,eAAA,CAGF,uFACE,eAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".component {\n  padding: 50px 0 80px;\n  background-color: #ffea00;\n\n  .container {\n    @media (max-width: 768px) {\n      flex-wrap: wrap;\n      justify-content: space-around !important;\n    }\n  }\n\n  .item {\n    width: 30%;\n    min-width: 220px;\n    padding: 0px;\n    margin: 30px 0;\n    text-align: center;\n    @media (max-width: 768px) {\n      width: 47%;\n    }\n    @media (max-width: 480px) {\n      width: 100%;\n    }\n\n    .title {\n      font-size: 52px;\n      font-weight: 700;\n    }\n\n    .text {\n      margin: 15px 0 0;\n      font-size: 24px;\n      font-weight: 700;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "fgzXXoeW3t914KIVGUPMFw==",
	"container": "oG7f1Ds-Jkyli0w8Xbjjzg==",
	"item": "qhhOOHeaHDLNF+jRvbLDMQ==",
	"title": "_8KsISkZTYbnS7X2cIjmQOw==",
	"text": "AW+Wk+W1BEcMGQAV7IxSuA=="
};
export default ___CSS_LOADER_EXPORT___;
