import {observable, action, toJS, makeObservable} from 'mobx';
import i18n from './i18n';
import momentjs from './moment';

class Store {
    constructor() {
        makeObservable(this,{
            user:observable,
            token:observable,
            refresh_token:observable,
            language:observable,
            loading:observable,
            toggleLoading:action.bound,
            setTokens:action,
            resetAuth:action,
            setUser:action,
            init:action,
            changeLanguage:action,
            changeLang:action,

        })
    }
    user = {};

     token = null;
     refresh_token = null;
     language = 'ru';

    loading = false;

    isLogged = false;
    toggleLoading(loading) {
        this.loading = this.loading === null && loading === false ? null : loading;
    }

    setTokens({token, refresh_token}) {
        localStorage.setItem('refresh_token', String(refresh_token));
        this.token = token;
        this.refresh_token = refresh_token;
    }


    resetAuth() {
        localStorage.removeItem('user');
        localStorage.removeItem('refresh_token');

        this.token = null;
        this.refresh_token = null;
        this.user = {};
    }

    setUser(user = {}) {
        this.user = {...this.user, ...user};

        localStorage.setItem('user', JSON.stringify(toJS(this.user)));
    }


    init(data) {
        const {
            language,
            refresh_token,
            user,
        } = data;
        this.refresh_token = refresh_token;
        this.language = language || 'ru';
        this.user = user;
        i18n.changeLanguage(language);
        momentjs.locale(language);
    }
    changeLanguage(language) {
        language = language || 'ru';
        this.language = language;
        i18n.changeLanguage(language);
        momentjs.locale(language);
        localStorage.setItem('language', language);
    }
    changeLang(lang){
        lang = this.language === 'ru' ? 'kk' : 'ru';
        this.language = lang;
        i18n.changeLanguage(lang);
        momentjs.locale(lang);
        localStorage.setItem('language', lang);
    }
}

const store = new Store();

export default store;