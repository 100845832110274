// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9Xv2JtUHk8vml2Jf-so6Zw\\=\\={padding:0;background-color:#ffea00}._9Xv2JtUHk8vml2Jf-so6Zw\\=\\= img{display:flex;max-width:100%}", "",{"version":3,"sources":["webpack://./src/views/utils/components/Banner/Banner.module.scss"],"names":[],"mappings":"AAAA,6BACE,SAAA,CACA,wBAAA,CAEA,iCACE,YAAA,CACA,cAAA","sourcesContent":[".component {\n  padding: 0;\n  background-color: #ffea00;\n\n  img {\n    display: flex;\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_9Xv2JtUHk8vml2Jf-so6Zw=="
};
export default ___CSS_LOADER_EXPORT___;
