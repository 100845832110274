import React from 'react';
import styles from './Radiobutton.module.scss';
import {Classes} from "../../utils";

const RadioButton = ({ name, label, onChange, onClick, active }) => {

   return (
      <label className={styles.component} onClick={onClick}>
         <div className={styles.radioSwitch}>
            <input name={name} type="radio" onChange={onChange} />
            <span className={Classes.join([styles.radio, active ? styles.active : ''])}></span>
         </div>
         <div className={styles.label}>{label}</div>
      </label>
   );
};

export default RadioButton;
