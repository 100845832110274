// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UgxU5ZHc8h4gNo1ZL1ivaQ\\=\\={padding:70px 0 10px;background-color:#fff}@media(max-width: 960px){.UgxU5ZHc8h4gNo1ZL1ivaQ\\=\\= .oOna3jk8M0J6gXzDz3M7vw\\=\\={flex-wrap:wrap;justify-content:space-around !important}}.UgxU5ZHc8h4gNo1ZL1ivaQ\\=\\= .oOna3jk8M0J6gXzDz3M7vw\\=\\= .jadwkFmhUCSg57XuiBzB9w\\=\\={max-width:33.333%;padding:0 50px;margin:0 0 80px;text-align:center}@media(max-width: 960px){.UgxU5ZHc8h4gNo1ZL1ivaQ\\=\\= .oOna3jk8M0J6gXzDz3M7vw\\=\\= .jadwkFmhUCSg57XuiBzB9w\\=\\={min-width:310px;padding:0 10px}}@media(max-width: 640px){.UgxU5ZHc8h4gNo1ZL1ivaQ\\=\\= .oOna3jk8M0J6gXzDz3M7vw\\=\\= .jadwkFmhUCSg57XuiBzB9w\\=\\={width:100%}}.UgxU5ZHc8h4gNo1ZL1ivaQ\\=\\= .oOna3jk8M0J6gXzDz3M7vw\\=\\= .jadwkFmhUCSg57XuiBzB9w\\=\\= .zpHLjX\\+EGHZP2-LZeWtRgA\\=\\={margin:25px 0 0;font-size:14px;font-weight:700;line-height:24px}", "",{"version":3,"sources":["webpack://./src/views/Home/components/CreditConditions/CreditConditions.module.scss"],"names":[],"mappings":"AAAA,4BACE,mBAAA,CACA,qBAAA,CAGE,yBADF,wDAEI,cAAA,CACA,uCAAA,CAAA,CAEF,oFACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,yBALF,oFAMI,eAAA,CACA,cAAA,CAAA,CAEF,yBATF,oFAUI,UAAA,CAAA,CAEF,iHACE,eAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".component {\n  padding: 70px 0 10px;\n  background-color: #fff;\n\n  .items {\n    @media(max-width: 960px){\n      flex-wrap:wrap;\n      justify-content: space-around !important;\n    }\n    .item {\n      max-width: 33.333%;\n      padding: 0 50px;\n      margin: 0 0 80px;\n      text-align: center;\n      @media(max-width: 960px){\n        min-width: 310px;\n        padding: 0 10px;\n      }\n      @media(max-width: 640px){\n        width: 100%;\n      }\n      .text {\n        margin: 25px 0 0;\n        font-size: 14px;\n        font-weight: 700;\n        line-height: 24px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "UgxU5ZHc8h4gNo1ZL1ivaQ==",
	"items": "oOna3jk8M0J6gXzDz3M7vw==",
	"item": "jadwkFmhUCSg57XuiBzB9w==",
	"text": "zpHLjX+EGHZP2-LZeWtRgA=="
};
export default ___CSS_LOADER_EXPORT___;
