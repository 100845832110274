import React, {Component} from 'react';
import styles from './Notification.module.scss';
import {NotificationItem} from "../index";
import {observer} from "mobx-react";
import {observable, action} from 'mobx';
import store from "../../../../../services/store";
import FlatList from 'flatlist-react';
import {Classes} from "../../../../../utils";
import NotificationsStore from "../../../../../stores/NotificationsStore";


@observer
class Notification extends Component {
    list = React.createRef();

    // @action
    // _scrollTop = () => {
    //     this.scrollButton = false;
    //     this.list.current.scrollToIndex({index: 0, animated: true});
    // };
    // @action
    // _onRefresh = () => {
    //     if (!this.props.listStore.refreshing) {
    //         this.props.listStore.setRefreshing(true);
    //         this.props.listStore.getItems(1);
    //     }
    // };
    //
    // @action
    // _onEndReached = () => {
    //     if (
    //         !this.props.listStore.loading &&
    //         this.props.listStore.query.page < this.props.listStore.totalPages
    //     ) {
    //         this.props.listStore.setLoading(true);
    //         this.props.listStore.getItems(this.props.listStore.query.page + 1);
    //     }
    // };

    // @action
    // onMomentumScrollBegin = event => {
    //     this.scrollButton = event.nativeEvent.contentOffset.y > 70;
    // };

    componentDidMount() {
        store.toggleLoading(true);
        this.getItems();
    }

    getItems = async () => {
        try {
            // const data = await this.props.listStore.getItems();
            const data = await NotificationsStore.getItems();
            // this.props.onGetItems && this.props.onGetItems(data);
        } catch (error) {
        }
    };

    renderItem = (item, idx) => {
        return (
            <NotificationItem key={item.id} item={item}/>
        )
    }

    render() {
        return (
            <div className={Classes.join([styles.component, "flex column a-center j-start"])}>
                <h2>Уведомления</h2>
                <FlatList list={NotificationsStore.items} renderItem={this.renderItem}/>
                {/*{NotificationsStore.items.map(this.renderItem)}*/}
            </div>

        )
    }
}

export default Notification;
