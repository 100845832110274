import React, {Component, useState, useEffect} from 'react';
import styles from "./FeedbackList.module.scss";
import store from "../../../services/store";
import {Link, Redirect} from 'react-router-dom';
import Classes from "../../../utils/classes";
import {Button} from "../../../components";
import FlatList from 'flatlist-react';
import momentjs from 'moment';



import {action, computed, makeObservable, observable} from "mobx";
import {observer} from "mobx-react";

import API from "../../../services/api";

import {withTranslation} from "react-i18next/hooks";

class FeedbacksStore {
    constructor() {
        makeObservable(this, {
            _page: observable,
            _feedbacks: observable,
            _loading: observable,
            loading: computed,
            page: computed,
            feedbacks: computed,
            getFeedbacks: action,
        })
    }

    _page = 1;
    get page() {
        return this._page;
    }

    set page(v) {
        this._page = v;
    }

    _loading = false;
    get loading() {
        return this._loading;
    }

    set loading(v) {
        this._loading = v;
    }

    _feedbacks = [];
    get feedbacks() {
        return this._feedbacks.slice();
    }

    set feedbacks(v) {
        this._feedbacks = v;
    }

    pages = 1;

    getFeedbacks = async (page = 1) => {
        try {
            this.loading = true;
            const {data} = await API.get('feedbacks/me', {params: page});
            data.docs = data.docs || [];
            this.feedbacks =
                page === 1 ? data.docs : [...this.feedbacks, ...data.docs];
            this.page = data.page;
            this.pages = data.pages;
        } catch (error) {
            console.log(error);
        } finally {
            store.toggleLoading(false);
            this.loading = false;
        }
    };
}

@observer
class FeedbackList extends Component {
    feedbacksStore = new FeedbacksStore();

    componentDidMount() {
        store.toggleLoading(true);
        this.feedbacksStore.getFeedbacks(1);
    }

    renderItem = (item) => {
        return (
            <FeedbackListItem {...this.props} key={item._id} item={item}/>
        )
    }

    render() {
        if (!store.refresh_token) {
            return <Redirect to="/cabinet/login"/>
        }
        return (
            <div className={Classes.join([styles.component, 'container flex column a-center j-center'])}>
                <h2 className='mb-2'>Ваши обращения</h2>
                <div className={Classes.join([styles.link, "mb-6"])}>
                    <Link to='/cabinet/feedback'>
                        <Button theme='secondary' text='Отправить обращение'/>
                    </Link>
                </div>
                <div className={styles.feedBack_container}>

                    {this.feedbacksStore.feedbacks &&
                    <FlatList
                        list={this.feedbacksStore.feedbacks}
                        renderItem={this.renderItem}
                        renderWhenEmpty={() => <p>У Вас нет обращений</p>}
                        renderOnScroll
                    />
                    }
                </div>
            </div>
        )
    }
}

const FeedbackListItem = ({item, id,t}) => {

    const [show, setShow] = useState(false);

    const Details = ({description, answer = ''}) => (
        <div className={Classes.join([styles.details,'mt-3'])}>
            <hr/>
           <div className='mt-3'>
               <h5>{t('FEEDBACK_QUESTION')}:</h5>
               <p>{description}</p>
           </div>
            { answer && <div className='mt-2'>
                <h5>{t('FEEDBACK_ANSWER')}:</h5>
                <p>{answer}</p>
            </div>}
        </div>
    )
    return (
        <div
            className={Classes.join([styles.feedbackList_item, 'flex column a-start mb-2 pt-2 pb-2 pl-4 pr-4'])}
            onClick={()=>setShow((s)=>!s)}
        >
            <h4>{item.title}</h4>
            <p className={styles.date}>{t('FEEDBACK_OPENED_DATE')} {' '}
                {momentjs(item.OPENED_DATE).format('DD.MM.YYYY, HH:mm')}</p>
            <span
                className={Classes.join([styles.status, item.status === 'OPENED' ? styles.closed : styles.opened, 'mt-2'])}
            >
                {item.status === 'OPENED' ? t(`FEEDBACKS_${item.status}`) : t(`FEEDBACKS_${item.status}`)}
            </span>
            {show && <Details description={item.question} answer={item.answer}/>}
        </div>
    )
}

export default withTranslation()(FeedbackList);
