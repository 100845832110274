import { observer } from 'mobx-react';
import React from 'react';

import { Container } from '../../../../components';
import bannerRu from './assets/images/banner-background-ru.png';
import styles from './Banner.module.scss';

const Banner = observer(() => {
  return (
    <Container className={styles.component}>
      <img src={bannerRu} alt="banner-background" />
    </Container>
  );
});

export default Banner;
