import React from 'react';
import {Banner, CreditСalculator, Contacts, ContactForm, Filials} from '../utils/components';
import { About, ServicesConditions, CreditConditions, FAQ } from './components';

const Main = () => {

  return (
    <>
      <Banner />
      <CreditСalculator />
      <About />
      <CreditConditions />
      <FAQ />
      {/*<Contacts />*/}
      <Filials visible={false}/>
      <ContactForm />
    </>
  );
};

export default Main;
