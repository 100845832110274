import React from 'react';
import { Classes } from '../../utils';

const Container = ({ children, className }) => {
   return (
      <div className={Classes.join([className])}>
         <div className="container">{children}</div>
      </div>
   );
};

export default Container;
