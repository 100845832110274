import React, {Component} from 'react';
import {Button, Container, Form, Input} from "../../../components";
import {Classes} from "../../../utils";
import {observer} from 'mobx-react';
import {makeObservable, observable, toJS, action, computed, configure} from 'mobx';
import {Link, Redirect} from "react-router-dom"
import {withTranslation} from "react-i18next/hooks";

import styles from './ForgetPassword.module.scss';
import API from "../../../services/api";
import store from "../../../services/store";

class ForgetStore {
    constructor(props) {
        this.props = props;
        makeObservable(this,{
            email:observable,
            setEmail:action,
            reset:action,
            formInvalid:computed,
        })
    }
    email = '';
    setEmail = (name, value) => {
        this.email = value ? value.trim() : '';
    };

    reset = async () => {
        try {
            store.toggleLoading(true);
            await API.post('users/auth/reset', {email: this.email});
            this.props.history.goBack();
        } catch (error) {
        } finally {
            store.toggleLoading(false);
        }
    };
    regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    get formInvalid() {
        return this.email.length !== 0 && this.regEx.test(this.email) === true;
    }
}

@observer
class ForgetPassword extends Component {

    forgetStore = new ForgetStore(this.props);

    render() {
        const {t} = this.props;
        return (
            <Container className={styles.component}>
                <div className="flex j-center a-center">
                    <div className={Classes.join([styles.form])}>
                        <Form theme="auth">
                            <Input onInput={this.forgetStore.setEmail} type="email"
                                   value={this.forgetStore.email}
                                   name="email" placeholder={t('EMAIL_RESET')}/>
                            <div className="flex j-around a-center">
                                <Button disabled={!this.forgetStore.formInvalid}
                                        theme="secondary" text={t('SEND')}
                                        type="submit" size="middle"
                                        onClick={this.forgetStore.reset}
                                />
                                <Link to='/cabinet/login'>Назад</Link>
                            </div>
                        </Form>
                    </div>
                </div>

            </Container>
        )
    }
}

export default withTranslation()(ForgetPassword);
