import React from 'react';
import {Container} from '../../../../components';
import styles from './About.module.scss';
import {Classes} from "../../../../utils";
import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import {observer} from "mobx-react";


const About = observer(() => {

        const loadData = async () => {
            const {data} = await publicAPI.get('/about-digits');
            return data;
        }
        const [data, setData] = React.useState([]);

        React.useEffect(() => {
            loadData().then(res => setData(res))
        }, [store.language])

        return (
            <Container className={styles.component}>
                <div className="flex j-center">
                    <h2 className={styles.title}>{data.title && (data.title[store.language] ? data.title[store.language] : data.title.ru)}</h2>
                </div>
                <div className={Classes.join([styles.container, "flex a-center j-between mt-5"])}>
                    {data && data.list && data.list.map((item, index) => (
                        <div className={styles.item} key={index}>
                            <div
                                className={styles.title}>{item && item.title && (item.title[store.language] ? item.title[store.language] : item.title.ru)}</div>
                            <div
                                className={styles.text}>{item && item.text && (item.text[0][store.language] ? item.text[0][store.language] : item.text[0].ru)}</div>
                        </div>
                    ))}
                </div>
            </Container>
        );
    }
);

export default About;
