// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3RpVj8IDjUbnRpKWOOMtwQ\\=\\={padding:65px 0;background-color:#ffea00}._3RpVj8IDjUbnRpKWOOMtwQ\\=\\= .CQLBRrS4bQULqTv6RETv9A\\=\\={max-width:1060px;margin-right:auto;margin-left:auto}._3RpVj8IDjUbnRpKWOOMtwQ\\=\\= .CQLBRrS4bQULqTv6RETv9A\\=\\= ._5NS7rfQVICRLq6L8RZkjwQ\\=\\=,._3RpVj8IDjUbnRpKWOOMtwQ\\=\\= .CQLBRrS4bQULqTv6RETv9A\\=\\= .qQ\\+emWMpacibQHFOGNVFeA\\=\\={width:100%}._3RpVj8IDjUbnRpKWOOMtwQ\\=\\= .CQLBRrS4bQULqTv6RETv9A\\=\\= ._5NS7rfQVICRLq6L8RZkjwQ\\=\\={max-width:440px}._3RpVj8IDjUbnRpKWOOMtwQ\\=\\= .CQLBRrS4bQULqTv6RETv9A\\=\\= .qQ\\+emWMpacibQHFOGNVFeA\\=\\={max-width:430px}._3RpVj8IDjUbnRpKWOOMtwQ\\=\\= .CQLBRrS4bQULqTv6RETv9A\\=\\= .qQ\\+emWMpacibQHFOGNVFeA\\=\\= img{max-width:100%}", "",{"version":3,"sources":["webpack://./src/views/utils/components/ContactForm/ContactForm.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CACA,wBAAA,CAEA,yDACE,gBAAA,CACA,iBAAA,CACA,gBAAA,CACA,4KAEE,UAAA,CAGF,sFACE,eAAA,CAGF,sFACE,eAAA,CAEA,0FACE,cAAA","sourcesContent":[".component {\n  padding: 65px 0;\n  background-color: #ffea00;\n\n  .container {\n    max-width: 1060px;\n    margin-right: auto;\n    margin-left: auto;\n    .form,\n    .image {\n      width: 100%;\n    }\n\n    .form {\n      max-width: 440px;\n    }\n\n    .image {\n      max-width: 430px;\n\n      img {\n        max-width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_3RpVj8IDjUbnRpKWOOMtwQ==",
	"container": "CQLBRrS4bQULqTv6RETv9A==",
	"form": "_5NS7rfQVICRLq6L8RZkjwQ==",
	"image": "qQ+emWMpacibQHFOGNVFeA=="
};
export default ___CSS_LOADER_EXPORT___;
