// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JSQkH9cG26pyLVsQP\\+Whow\\=\\= input{width:100%;padding:15px 20px;font-family:\"Lato\",sans-serif;color:#000;border:3px solid rgba(0,0,0,0);outline:none;border-radius:8px;transition:all .2s}.JSQkH9cG26pyLVsQP\\+Whow\\=\\= input::placeholder{color:rgba(0,0,0,.5)}.JSQkH9cG26pyLVsQP\\+Whow\\=\\=.WzYbfZG4LnXB0TEx8bZH7A\\=\\= input{margin:0 0 5px;box-shadow:0 0 0 3px rgba(244,67,54,.4)}.JSQkH9cG26pyLVsQP\\+Whow\\=\\=.WzYbfZG4LnXB0TEx8bZH7A\\=\\= small{font-weight:700;color:#f44336}", "",{"version":3,"sources":["webpack://./src/components/PhoneInput/PhoneInput.module.scss"],"names":[],"mappings":"AACE,mCACE,UAAA,CACA,iBAAA,CACA,6BAAA,CACA,UAAA,CACA,8BAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CAEA,gDACE,oBAAA,CAKF,8DACE,cAAA,CACA,uCAAA,CAGF,8DACE,eAAA,CACA,aAAA","sourcesContent":[".component {\n  input {\n    width: 100%;\n    padding: 15px 20px;\n    font-family: 'Lato', sans-serif;\n    color: #000;\n    border: 3px solid transparent;\n    outline: none;\n    border-radius: 8px;\n    transition: all 0.2s;\n\n    &::placeholder {\n      color: rgba(#000, 0.5);\n    }\n  }\n\n  &.invalid {\n    input {\n      margin: 0 0 5px;\n      box-shadow: 0 0 0 3px rgba(#f44336, 0.4);\n    }\n\n    small {\n      font-weight: 700;\n      color: #f44336;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "JSQkH9cG26pyLVsQP+Whow==",
	"invalid": "WzYbfZG4LnXB0TEx8bZH7A=="
};
export default ___CSS_LOADER_EXPORT___;
