// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IS7SCXrz10PMmCwyvLuqqg\\=\\={width:100%}.IS7SCXrz10PMmCwyvLuqqg\\=\\= h2{margin-bottom:100px}.IS7SCXrz10PMmCwyvLuqqg\\=\\= input{box-shadow:0px 4px 26px rgba(0,0,0,.06);margin:15px 0px}.IS7SCXrz10PMmCwyvLuqqg\\=\\= form{width:50%}.IS7SCXrz10PMmCwyvLuqqg\\=\\= form button{color:#000 !important;margin:15px auto}", "",{"version":3,"sources":["webpack://./src/views/cabinet/utils/components/ChangeLanguage/ChangeLanguage.module.scss"],"names":[],"mappings":"AAAA,4BACE,UAAA,CACA,+BACE,mBAAA,CAEF,kCACE,uCAAA,CACA,eAAA,CAEF,iCACE,SAAA,CACA,wCACE,qBAAA,CACA,gBAAA","sourcesContent":[".component{\n  width: 100%;\n  & h2{\n    margin-bottom: 100px;\n  }\n  input{\n    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);\n    margin: 15px 0px;\n  }\n  form{\n    width: 50%;\n    button{\n      color:#000 !important;\n      margin: 15px auto;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "IS7SCXrz10PMmCwyvLuqqg=="
};
export default ___CSS_LOADER_EXPORT___;
