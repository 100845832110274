import React, {useState} from 'react';
import {Container} from '../../../../components';
import {Classes} from '../../../../utils';
import styles from './ServicesConditions.module.scss';
import {Conditions, Confidentiality, ServiceQuality} from './Tabs';
import ReactMarkdown from "react-markdown";
import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import {observer} from "mobx-react";

// TODO: Заполнить текстом табы

const ServicesConditions = observer(() => {
    const [state, setState] = useState({
        tabs: [
            // { text: 'Условия', id: 1, view: <Conditions /> },
            // { text: 'Конфиденциальность', id: 2, view: <Confidentiality /> },
            // { text: 'Служба качества', id: 3, view: <ServiceQuality /> },
        ],
        activeTab: 1,
    });

    const setActiveTab = (id) => {
        const tab = data.list.find((tab) => tab.id === id);
        setState((prevState) => ({...prevState, activeTab: tab.id}));
    };

    const loadData = async () => {
        const {data} = await publicAPI.get('/terms-and-conditions');
        return data;
    }
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        loadData().then(res => {
            setData(res);
            setState((prevState) => ({...prevState, tabs: data.list}));
        });

    }, [store.language])


    return (
        <Container className={styles.component}>
            <div className="flex j-center">
                <h2>{data.title && (data.title[store.language] ? data.title[store.language] : data.title.ru)}</h2>
            </div>
            <div className={styles.container}>
                <div className={Classes.join([styles.tabs, 'flex j-center mt-5'])}>
                    {data.list && data.list.map(({title, id,}) => (
                        <Tab
                            key={id}
                            text={title && (title[store.language] ? title[store.language] : title.ru)}
                            active={state.activeTab === id}
                            onClick={() => setActiveTab(id)}
                        />
                    ))}
                </div>
                <div className="mt-35">
                    {data.list && data.list.map(({ id, text }, index) => (
                      <TabView active={state.activeTab === id} key={index}>
                        <TabContent text={text && (text[store.language] ? text[store.language] : text.ru)}/>
                      </TabView>
                    ))}
                </div>
            </div>
        </Container>
    );
});


const Tab = ({text, active, onClick}) => (
    <div className={Classes.join([styles.tab, active ? styles.active : null])} onClick={onClick}>
        {text}
    </div>
);

const TabView = ({children, active}) =>
    (active ? <div className={styles.view}>{children}</div> : null)

const TabContent = ({title, text}) => {
    return (
        <ReactMarkdown>
            {text}
        </ReactMarkdown>
    )
}

export default ServicesConditions;
