// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KcrcfGQhGW97\\+9AnAfj1Ow\\=\\={width:100%}.KcrcfGQhGW97\\+9AnAfj1Ow\\=\\= h2{margin-bottom:100px}", "",{"version":3,"sources":["webpack://./src/views/cabinet/utils/components/Payments/Payments.module.scss"],"names":[],"mappings":"AAAA,6BACE,UAAA,CACA,gCACE,mBAAA","sourcesContent":[".component{\n  width: 100%;\n  & h2{\n    margin-bottom: 100px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "KcrcfGQhGW97+9AnAfj1Ow=="
};
export default ___CSS_LOADER_EXPORT___;
