import React from 'react';
import {Container} from '../../../../components';
import {Classes} from '../../../../utils';
import {Button, Form, Input} from '../../../../components';
import styles from './Vacancies.module.scss';
import ReactMarkdown from "react-markdown";

import publicAPI from "../../../../services/publicApi";
import store from "../../../../services/store";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next/hooks";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import {computed, makeObservable, observable, action} from "mobx";
import {toast} from "react-toastify";

const duties = [
        {text: 'Консультирование клиентов по правилам ломбардных операций'},
        {text: 'Оценка и прием залогов'},
        {text: 'Оформление договоров займа'},
        {text: 'Выдача займов под залог движимого имущества'},
        {text: 'Реализация залогового имущества на торгах'},
    ],
    requirements = [
        {text: 'Обучаемость'},
        {text: 'Стрессоустойчивость'},
        {text: 'Коммуникабельность'},
        {text: 'Ответственность'},
        {text: 'Порядочность'},
    ],
    conditions = [
        {text: 'Карьерный рост'},
        {text: 'Обучение'},
        {text: 'Официальное оформление'},
        {text: '5/2 (плавающий график), сменный'},
    ];


const TELEGRAM_TOKEN = '1682418127:AAEQCfaHF8mpUb9-PZUy9Tk0dKxVUMvDjRA';
const TELEGRAM_CHAT_ID = 275467043;

class ContactStore {
    form = {
        name: '',
        phone: '',
        email: ''
    }
    redirect = false;

    constructor() {
        makeObservable(this, {
            redirect: observable,
            form: observable,
            onChange: action,
            onChangePassword: action,
            onPress: action,
            formInvalid: computed
        })
    }

    onChange = (field, value) => {
        this.form[field] = value;
    };
    onChangePassword = (field, value) => {
        this.form[field] = value;
    };
    onPress = async () => {
        try {
            const TELEGRAM_TEXT = encodeURI("<b>Отклик на вакансию на сайте SuperLombard</b>\n\n" +
                `<b>Имя:</b> ${this.form.name}\n` +
                `<b>Email:</b> ${this.form.email}\n` +
                `<b>Телефон:</b> +${this.form.phone}`);

            store.toggleLoading(true)
            const q = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage?chat_id=${TELEGRAM_CHAT_ID}&text=${TELEGRAM_TEXT}&parse_mode=HTML`;
            console.log(q);
            const test = await fetch(q)
                .then(res => {
                    if (res.status === 200) {
                        toast('Заявка успешно отправлена!', {
                            position: toast.POSITION.TOP_CENTER,
                            type: toast.TYPE.SUCCESS,
                            autoClose: 8000,
                        });
                    }
                })
                .catch(err => console.error(err))
                .finally(() => {
                    this.onChange('email', '')
                    this.onChange('name', '')
                    this.onChange('phone', '')

                })

        } catch (error) {
        } finally {
            store.toggleLoading(false);

        }
    };
    regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    get formInvalid() {
        return this.form.phone.length === 16 &&
            this.form.name.length !== 0 &&
            (this.form.email.length > 0 &&
                this.form.email.length < 256 &&
                this.regEx.test(this.form.email) === true)
    }
}

const contactStore = new ContactStore();

const Vacancies = observer(({t}) => {
        const loadData = async () => {
            const {data} = await publicAPI.get('/vacancies');
            return data;
        }
        const [data, setData] = React.useState([]);
        const [list, setList] = React.useState([]);

        React.useEffect(() => {
            loadData().then(res => {
                setData(res);
                return res.vacancies_list;
            }).then(res => setList(res))
        }, [store.language])

        return (
            <Container className={styles.component}>
                <div className="flex j-center">
                    <h2>{data.title && (data.title[store.language] ? data.title[store.language] : data.title.ru)}</h2>
                </div>
                <div className={Classes.join([styles.container, 'flex j-between mt-7'])}>
                    <div className={styles.image}>
                        <img src={`${process.env.REACT_APP_IMG_URL}${data.image ? data.image.url : ''}`}
                             alt="Вакансии"/>
                    </div>
                    <div className={styles.content}>
                        <div className={Classes.join([styles.block, 'flex column'])}>
                            <h4>{t('Employment_questions')}: </h4>
                            <div className={Classes.join([styles.text, 'mt-2'])}>
                                <ReactMarkdown>
                                    {data.text && (data.text[store.language] ? data.text[store.language] : data.text.ru)}
                                </ReactMarkdown>

                            </div>

                        </div>
                        <div className="mt-3">
                            <Block
                                title={list && list[0] && (list[0].title[store.language] ? list[0].title[store.language] : list[0].title.ru)}
                                list={list && list[0] && (list[0].text ? list[0].text : [])}/>
                        </div>
                        <div className="flex j-between mt-3">
                            <Block
                                title={list && list[1] && (list[1].title[store.language] ? list[1].title[store.language] : list[1].title.ru)}
                                list={list && list[1] && (list[1].text ? list[1].text : [])}/>
                            <Block
                                title={list && list[2] && (list[2].title[store.language] ? list[2].title[store.language] : list[2].title.ru)}
                                list={list && list[2] && (list[2].text ? list[2].text : [])}/>
                        </div>
                        <div className={Classes.join([styles.form, 'mt-45'])}>
                            <Form theme="secondary">
                                <Input placeholder={t('NAME_SURNAME')} onInput={contactStore.onChange} type="text"
                                       name="name" value={contactStore.form.name}/>
                                <Input placeholder="E-mail" onInput={contactStore.onChange} type="email" name="email"
                                       value={contactStore.form.email}/>
                                <PhoneInput onChange={contactStore.onChange} type="text" name="phone"
                                            placeholder={t('PHONE')}
                                            value={contactStore.form.phone}
                                />
                                <Button disabled={!contactStore.formInvalid} onClick={contactStore.onPress}
                                        text={t('SUBMIT_REQUEST')} type="submit" theme="secondary" size="middle"/>
                            </Form>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
);

const Block = ({title, list}) => (
    <div className={Classes.join([styles.block, 'flex column'])}>
        <h4>{title}:</h4>
        <ul className={Classes.join([styles.list, 'mt-15'])}>
            {list && list.map((item, index) => (
                <li className={styles.item} key={index}>
                    {item && (item[store.language] ? item[store.language] : item.ru)}
                </li>
            ))}
        </ul>
    </div>
);

export default withTranslation()(Vacancies);
