import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/index.scss';
import reportWebVitals from './reportWebVitals';

import './services/i18n';

// eslint-disable-next-line no-extend-native
Number.prototype.format = function() {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

import moment from "moment";
// eslint-disable-next-line no-extend-native
String.prototype.formatDate = function(format) {
    return moment(this.toString()).format(format);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
